// This will include and overwrite the default theme
module.exports = {
	colors: {
		primary: {
			value: 'var(--color-theme-subsite-purple-primary)',
			isListedRgb: true,
		},
		'primary-dark': {
			value: 'var(--color-theme-subsite-purple-primary)',
			isListedRgb: true,
		},
		secondary: {
			value: 'var(--color-theme-subsite-purple-secondary)',
			isListedRgb: true,
		},
	},

	spacing: {
		/* Other special spacings */
		'site-header': {
			sm: 82,
			md: 106,
			lg: 106,
		},
		'site-header-compact': {
			sm: 82,
			md: 106,
			lg: 106,
		},

		/* Just used on subsites */
		'header-logo-width': {
			sm: 132,
			md: 260,
			lg: 260,
		},
		'header-logo-height': {
			sm: 36,
			md: 48,
			lg: 48,
		},
	},

	// fontSize (and lineHeight and letterSpacing) is a special setup, as special rules are generated
	fontSize: {
		/** Heading  */
		'h1-frontpage': {
			fontSize: {
				sm: 44,
				md: 84,
				lg: 84,
			},
			lineHeight: {
				sm: 0.9,
				md: 0.83,
				lg: 0.83,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		/** Teasers */
		'teaser-frontpage': {
			fontSize: {
				sm: 19,
				md: 30,
				lg: 30,
			},
			lineHeight: {
				sm: 1,
				md: 1.33,
				lg: 1.33,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		/** Logo */
		logo: {
			fontSize: {
				sm: 18,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 0.9,
				md: 0.9,
				lg: 0.9,
			},
			letterSpacing: {
				sm: 0.1,
				md: 0.1,
				lg: 0.1,
			},
		},
	},
};
