const { hexToRgb } = require('./utilities/colors');

/*
	The default config should typically be set as the values of
	the main solution. These can then be overwritten by the values
	coming from backend (handled in the ThemeConfiguration.vue component).

	You should never remove keys from this object, as Limbo Citi Baseline
	styles might be relying on them, but new keys may be added if needed.
*/
module.exports = {
	// Testing only - set to false when done testing
	minify: true, // Can be turned to false for a more readable output in the style tag

	// Setup
	baseFontSize: 16, // For rem conversion
	smViewport: 375, // Lowest value clamp
	mdViewport: 1440, // Midpoint (used for both low-clamp and high-clamp)
	lgViewport: 1440, // Highest value clamp

	// Rules
	colors: {
		/*
			CSS variables will be created and used for the colors,
			which will in turn be used in the Tailwind config. Keys
			will be directly transfered to the Tailwind config.

			"primary: '#000000'" will result in the Tailwind rule:
			"primary: var(--theme-colors-primary, #000000)"

			If configuring a color with three comma-separated numbers,
			the built-in Tailwind color opacities will be used in the
			rules.
		*/
		text: hexToRgb('#2d2d2d'),
		'text-placeholder': hexToRgb('#686868'),

		primary: {
			value: 'var(--color-theme-main-blue-primary)',
			isListedRgb: true,
		},
		'primary-dark': {
			value: 'var(--color-theme-main-blue-primary)',
			isListedRgb: true,
		},
		secondary: {
			value: 'var(--color-theme-main-blue-secondary)',
			isListedRgb: true,
		},

		background: hexToRgb('#EFEFEF'),
		error: hexToRgb('#C42F00'),
		breaking: hexToRgb('#FFE815'),
	},

	layout: {
		margin: {
			sm: 16,
			md: 64,
			lg: 64,
		},

		gutter: {
			sm: 16,
			md: 32,
			lg: 32,
		},

		// These rules will be turned into `X/Ycol` rules, which can then be used like `w-3/12col`.
		// There should always be at least one column, both on sm, md and lg.
		columns: {
			sm: 5,
			md: 12,
			lg: 12,
		},

		// The max value that the design can be scaled to (single value, not sm-md-lg).
		// The max will impact columns max scaling as well.
		// undefined equals no max.
		max: 1440,
	},

	spacing: {
		'2xs': {
			sm: 4,
			md: 6,
			lg: 6,
		},
		xs: {
			sm: 8,
			md: 12,
			lg: 12,
		},
		sm: {
			sm: 12,
			md: 16,
			lg: 16,
		},
		md: {
			sm: 16,
			md: 24,
			lg: 24,
		},
		lg: {
			sm: 20,
			md: 32,
			lg: 32,
		},
		xl: {
			sm: 24,
			md: 40,
			lg: 40,
		},
		'2xl': {
			sm: 32,
			md: 50,
			lg: 50,
		},
		'3xl': {
			sm: 40,
			md: 64,
			lg: 64,
		},
		'4xl': {
			sm: 64,
			md: 80,
			lg: 80,
		},
		'5xl': {
			sm: 80,
			md: 100,
			lg: 100,
		},
		'6xl': {
			sm: 80,
			md: 120,
			lg: 120,
		},
		'7xl': {
			sm: 100,
			md: 140,
			lg: 140,
		},
		'8xl': {
			sm: 120,
			md: 160,
			lg: 160,
		},

		/* Other special spacings */
		'site-header': {
			sm: 100,
			md: 106,
			lg: 106,
		},
		'site-header-compact': {
			sm: 56,
			md: 72,
			lg: 72,
		},

		/* Just used on subsites */
		'header-logo-width': {
			sm: 0,
			md: 0,
			lg: 0,
		},
		'header-logo-height': {
			sm: 0,
			md: 0,
			lg: 0,
		},
	},

	// fontSize (and lineHeight and letterSpacing) is a special setup, as special rules are generated
	fontSize: {
		/**
		 * Corresponding design-values. Should at some
		 * point be parsed onto a designer who can write
		 * them into the design-file.
		 *
		 * h1 -> Page Title H1
		 * h1-frontpage -> Frontpage Title
		 * h2 -> Content Title Primary
		 * h3 -> Content Title Secondary
		 * h4 -> Content Title Tertiary
		 *
		 * body -> Text Paragraph
		 * body-link -> Text Bullets and Links ****
		 *
		 * aside -> Left Menu Text
		 * aside-title -> Left Menu Title
		 * menu-category -> Burgermenu primary text
		 *
		 * quote -> Quote text
		 * video-play -> Video play text
		 * input -> Input text
		 * input-dropdown -> Input text dropdown
		 *
		 * button -> CTA md
		 * button-sm -> CTA sm
		 * button-xs -> CTA xs
		 *
		 * teaser -> Page Manchet
		 * teaser-sm -> Teaser text sm
		 *
		 * title-card -> Card title
		 * title-spotbox -> Spotbox title
		 * title-spotbox-sm -> Spotbox title small
		 * title-breaking-banner -> Breaking banner title
		 *
		 * subject-tab -> Emneside tab title
		 * subject-link -> Emneside link title
		 *
		 * logo -> Logo text
		 *
		 * label-card -> Card label
		 * label-file -> File label text
		 * label-spotbox -> Spotbox label
		 *
		 * breadcrumb -> Breadcrumb text
		 * mit-id -> MIT ID text
		 *
		 * table-heading -> Table label ****
		 * table-secondary -> Table text secondary
		 *
		 * image-pagination -> Image Number
		 * image-copyright -> Copyright
		 * image-copyright-icon -> Copyright Icon
		 *
		 * form-help -> Form Help
		 * form-label -> Form Choice ****
		 * form-error -> Form Error
		 *
		 * search-infobox -> Search Infobox
		 * seatch-suggestion -> Search Suggestion
		 * search-subtitle -> Seatch Subtitle ****
		 */

		/** Heading  */
		h1: {
			fontSize: {
				sm: 44,
				md: 84,
				lg: 84,
			},
			lineHeight: {
				sm: 0.9,
				md: 0.83,
				lg: 0.83,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'h1-frontpage': {
			fontSize: {
				sm: 52,
				md: 84,
				lg: 84,
			},
			lineHeight: {
				sm: 0.96,
				md: 0.92,
				lg: 0.92,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		h2: {
			fontSize: {
				sm: 34,
				md: 50,
				lg: 50,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		h3: {
			fontSize: {
				sm: 26,
				md: 30,
				lg: 30,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		h4: {
			fontSize: {
				sm: 22,
				md: 26,
				lg: 26,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		/** Titles */
		'title-card': {
			fontSize: {
				sm: 24,
				md: 30,
				lg: 30,
			},
			lineHeight: {
				sm: 1,
				md: 1.06,
				lg: 1.06,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'title-spotbox': {
			fontSize: {
				sm: 50,
				md: 50,
				lg: 50,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'title-spotbox-sm': {
			fontSize: {
				sm: 28,
				md: 30,
				lg: 30,
			},
			lineHeight: {
				sm: 1.12,
				md: 1.12,
				lg: 1.12,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'title-breaking-banner': {
			fontSize: {
				sm: 26,
				md: 36,
				lg: 36,
			},
			lineHeight: {
				sm: 0.9,
				md: 0.9,
				lg: 0.9,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		/** Teasers */
		teaser: {
			fontSize: {
				sm: 28,
				md: 38,
				lg: 38,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0.04,
				lg: 0.04,
			},
		},
		'teaser-frontpage': {
			fontSize: {
				sm: 28,
				md: 38,
				lg: 38,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0.04,
				lg: 0.04,
			},
		},
		'teaser-sm': {
			fontSize: {
				sm: 14,
				md: 16,
				lg: 16,
			},
			lineHeight: {
				sm: 1.48,
				md: 1.48,
				lg: 1.48,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		/** Subject */
		'subject-tab': {
			fontSize: {
				sm: 22,
				md: 24,
				lg: 24,
			},
			lineHeight: {
				sm: 0.9,
				md: 0.9,
				lg: 0.9,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'subject-link': {
			fontSize: {
				sm: 20,
				md: 24,
				lg: 24,
			},
			lineHeight: {
				sm: 1,
				md: 1.1,
				lg: 1.1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		/** Labels */
		'label-card': {
			fontSize: {
				sm: 16,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'label-file': {
			fontSize: {
				sm: 13,
				md: 16,
				lg: 16,
			},
			lineHeight: {
				sm: 0.72,
				md: 0.72,
				lg: 0.72,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'label-spotbox': {
			fontSize: {
				sm: 18,
				md: 18,
				lg: 18,
			},
			lineHeight: {
				sm: 1.32,
				md: 1.32,
				lg: 1.32,
			},
			letterSpacing: {
				sm: 0.28,
				md: 0.28,
				lg: 0.28,
			},
		},

		/** Body */
		body: {
			fontSize: {
				sm: 16,
				md: 18,
				lg: 18,
			},
			lineHeight: {
				sm: 1.48,
				md: 1.6,
				lg: 1.6,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'body-link': {
			fontSize: {
				sm: 18,
				md: 18,
				lg: 18,
			},
			lineHeight: {
				sm: 1.3,
				md: 1.4,
				lg: 1.4,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		aside: {
			fontSize: {
				sm: 22,
				md: 22,
				lg: 22,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0.04,
				md: 0.04,
				lg: 0.04,
			},
		},
		'aside-title': {
			fontSize: {
				sm: 20,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		'longread-mobile': {
			fontSize: {
				sm: 20,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		'menu-category': {
			fontSize: {
				sm: 30,
				md: 46,
				lg: 46,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		quote: {
			fontSize: {
				sm: 46,
				md: 60,
				lg: 60,
			},
			lineHeight: {
				sm: 1,
				md: 1.1,
				lg: 1.1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'video-play': {
			fontSize: {
				sm: 22,
				md: 30,
				lg: 30,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0.04,
				md: 0.04,
				lg: 0.04,
			},
		},
		breadcrumb: {
			fontSize: {
				sm: 18,
				md: 18,
				lg: 18,
			},
			lineHeight: {
				sm: 0.8,
				md: 0.8,
				lg: 0.8,
			},
			letterSpacing: {
				sm: 0.04,
				md: 0.04,
				lg: 0.04,
			},
		},
		'mit-id': {
			fontSize: {
				sm: 16,
				md: 18,
				lg: 18,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		input: {
			fontSize: {
				sm: 20,
				md: 24,
				lg: 24,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'input-sm': {
			fontSize: {
				sm: 16,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'input-dropdown': {
			fontSize: {
				sm: 20,
				md: 24,
				lg: 24,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		'table-heading': {
			fontSize: {
				sm: 20,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1.2,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'table-secondary': {
			fontSize: {
				sm: 14,
				md: 16,
				lg: 16,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		'image-pagination': {
			fontSize: {
				sm: 18,
				md: 18,
				lg: 18,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0.28,
				md: 0.28,
				lg: 0.28,
			},
		},
		'image-copyright': {
			fontSize: {
				sm: 16,
				md: 18,
				lg: 18,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0.28,
				md: 0.28,
				lg: 0.28,
			},
		},
		'image-copyright-icon': {
			fontSize: {
				sm: 20,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		/** Buttons */
		button: {
			fontSize: {
				sm: 20,
				md: 24,
				lg: 24,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'button-sm': {
			fontSize: {
				sm: 18,
				md: 22,
				lg: 22,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'button-xs': {
			fontSize: {
				sm: 16,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0.04,
				md: 0.04,
				lg: 0.04,
			},
		},

		/** Logo */
		logo: {
			fontSize: {
				sm: 17,
				md: 19,
				lg: 19,
			},
			lineHeight: {
				sm: 0.87,
				md: 0.87,
				lg: 0.87,
			},
			letterSpacing: {
				sm: 0.09,
				md: 0.09,
				lg: 0.09,
			},
		},

		/** Form */
		'form-help': {
			fontSize: {
				sm: 18,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'form-label': {
			fontSize: {
				sm: 20,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 0.88,
				md: 0.88,
				lg: 0.88,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'form-error': {
			fontSize: {
				sm: 16,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1.1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},

		/** Search */
		'search-infobox': {
			fontSize: {
				sm: 20,
				md: 24,
				lg: 24,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'search-suggestion': {
			fontSize: {
				sm: 18,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'search-subtitle': {
			fontSize: {
				sm: 16,
				md: 18,
				lg: 18,
			},
			lineHeight: {
				sm: 1,
				md: 1,
				lg: 1,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
	},

	borderRadius: {
		md: {
			sm: 12,
			md: 20,
			lg: 20,
		},
		lg: {
			sm: 22,
			md: 34,
			lg: 34,
		},
	},
};
